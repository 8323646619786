@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600;700;800&display=swap");

/* Constant styles  */
$mainColor: #3259a8;
$secondColor: #fedd00;
// $thirdColor: #252C30;

@font-face {
  font-family: segr;
  src: url(../fonts/SegoeUI.ttf);
}

@font-face {
  font-family: segl;
  src: url(../fonts/segoeuil.ttf);
}

@font-face {
  font-family: segb;
  src: url(../fonts/SegoeUIBold.ttf);
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 16px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  font-family: "Baloo Bhaijaan 2", cursive;
  direction: rtl;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
}

body {
  line-height: normal !important;
  font-family: "Baloo Bhaijaan 2", cursive;
  direction: rtl;
}

.css-67l5gl {
  background-color: transparent;
  box-shadow: none !important;
}

.exportcsv {
  background-color: $mainColor;
  color: #fff;
  border: 0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

a,
button {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* // Constant Styles */

ul {
  margin-bottom: 0;
}

.form-control {
  border-radius: 7px;
  background-color: rgba(245, 247, 250, 0.386);

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

p {
  font-size: 1.1rem;
  color: #000;
}

/* Main Style */

.btn-style {
  background-color: $secondColor;
  padding: 14px 50px;
  color: #fff;
  font-size: 1.1rem;
  display: inline-block;
  text-align: center;
  border: 1px solid $secondColor;
  border-radius: 28px;
  text-align: center;
  // width: 200px;

  max-width: 100%;

  &:hover {
    color: $secondColor;
    background-color: #fff;
    border-color: $secondColor;
  }
}

.card-img {
  height: 100vh;
  position: relative;

  .img-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.algin-center {
  align-items: center;
}

.noauth {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    width: 400px;
    margin: auto;
  }
  h5 {
    font-weight: bold;
    font-size: 1.8rem;
  }
}

canvas {
  font-family: "Baloo Bhaijaan 2", cursive !important;
}

/*  */
.top_header {
  padding: 20px 0;
  box-shadow: 0 3px 6px rgba(#000, 0.05);

  .form-control {
    width: 100%;
    border: 0;
    background-color: #f3f3f3;
    box-shadow: 0 3px 6px rgba(#000, 0.05);
    height: 40px;
  }

  .search_input {
    .form-group {
      position: relative;

      i {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translate(0, -50%);
      }

      .form-control {
        padding-right: 40px;
      }
    }
  }

  .notifications {
    text-align: left;

    button,
    a {
      background-color: transparent;
      border: 0;
      position: relative;

      i {
        color: $mainColor;
        font-size: 1.5rem;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }

      .notification_number {
        position: absolute;
        top: -6px;
        left: -5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
      }
    }
  }
}

.notifications {
  position: relative;
  .notification_component {
    // position: absolute;
    background-color: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
    z-index: 4;
    border-radius: 10px;
    margin-top: 50px;
    // left: 0;
    // opacity: 0;
    // visibility: hidden;
    // transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
    width: 100%;
    h5 {
      text-align: right;
      background-color: $mainColor;
      padding: 15px;
      color: #fff;
      font-size: 1rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .norilist {
      padding: 0;
      text-align: right;
      list-style: none;
      padding: 15px;
      min-height: fit-content;
      max-height: 70vh;
      overflow: auto;
      li {
        position: relative;
        // padding-right: 15px;
        border-bottom: 1px solid #eee;
        padding: 10px 15px 10px 0;
        p {
          margin: 0;
          font-size: 0.9rem;
          line-height: 20px;
        }
        .data {
          font-size: 0.8rem;
          text-align: left;
          display: block;
        }
        &::before {
          content: "";
          width: 7px;
          height: 7px;
          position: absolute;
          right: 0;
          background-color: $secondColor;
          border-radius: 50%;
          top: 15px;
        }
      }
    }
  }
}

.table_style {
  position: relative;
}
// .previous_operations{
//   position: absolute;
//   top: 40px;
//   left: 30px;
//   display: block;
//   width: fit-content;
//   margin-right: auto;
//   text-align: left;
//   margin-bottom: 40px;
//   color: $mainColor;
//   background-color: transparent;
//   border: 0;
//   font-size: 1rem;
//   &::after {
//     content: "";
//     position: absolute;
//     bottom: 0px;
//     right: 0;
//     width: 100%;
//     height: 2px;
//     background-color: $mainColor;
//   }
// }

.tableoptions {
  span {
    width: 35px;
    height: 36px;
    background-color: #3259a8;
    color: #fff;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    svg {
      fill: #fff !important;
      width: 20px !important;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .editicon {
    padding-top: 5px;
    svg {
      // height: 30px;
    }
  }
  .historyicon {
    background-color: $secondColor;
    svg {
      fill: #000 !important;
    }
  }
  .infoicon {
    background-color: #333;
    svg {
      fill: #fff !important;
    }
  }
  .deleteicon {
    background-color: red;
  }
}

.history_dialog {
  .MuiPaper-root {
    width: 700px;
  }
  h2.MuiTypography-root {
    background-color: $mainColor;
    color: #fff;
    font-family: "Baloo Bhaijaan 2", cursive !important;
  }
  .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
    // padding-top: 20px;
  }
  .notfoundhistory {
    padding-top: 20px;
  }

  .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
    font-family: "Baloo Bhaijaan 2", cursive !important;
  }
  ul {
    margin-bottom: 0;
  }
  li {
    border-bottom: 1px solid #eee;
    padding: 15px 0;
    padding-bottom: 10px;
    p {
      font-size: 1rem;
    }
    .date {
      display: block;
      text-align: left;
      margin-top: 5px;
    }
  }
}

.notification_component_visible {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
}

.pending-status {
  .MuiDataGrid-cellContent {
    background-color: $secondColor;
    padding: 7px 12px;
    border-radius: 5px;
  }
}

.done-status {
  .MuiDataGrid-cellContent {
    background-color: $mainColor;
    padding: 7px 12px;
    color: #fff;
    border-radius: 5px;
  }
}

.main_content {
  background-color: #f4f4f4;
  position: relative;
  display: flex;
  .btn_style {
    background-color: $mainColor;
    color: #fff;
    padding: 11px 56px;
    text-align: center;
    border: 0;
    border: 1px solid $mainColor;
    border-radius: 10px;
    font-family: "Baloo Bhaijaan 2", cursive;
    cursor: pointer;
    &:hover {
      background-color: #fff;
      color: $mainColor;
    }
  }
  .main_content_wrapper {
    padding-right: 64px;
    padding-bottom: 60px;
    width: 85%;
    padding-left: 100px;
  }
}

.return_btn {
  position: absolute;
  left: 100px;
  top: 70px;
  a {
    display: flex;
    align-items: center;
    position: relative;
    color: $mainColor;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $mainColor;
    }
    i {
      margin-right: 10px;
    }
  }
}

.add_button {
  display: flex;
  margin-top: 64px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 123px;
    background-color: $secondColor;
    margin-left: 50px;
    border-radius: 10px;
    font-size: 1.3rem;
    color: $mainColor;
    font-weight: 500;
    border: 1px solid $secondColor;
    &:hover {
      background-color: #fff;
    }
  }
}

.side-nav {
  min-height: calc(100vh - 80px);
  height: auto;
  background-color: $mainColor;
  width: 15%;
  position: sticky;
  right: 0;
  top: 0;

  .person-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    .card-img {
      width: 60px;
      height: 60px;
      img {
        border-radius: 50%;
      }
    }
    .card-body {
      margin-right: 10px;
      h5,
      p {
        margin: 0;
        color: #fff;
      }
    }
  }

  .side_links {
    display: flex;
    margin-top: 28px;
    align-items: center;
    justify-content: center;
    ul {
      list-style: none;
      margin: 0;
      width: 100%;
    }
    li {
      margin-bottom: 10px;
      a {
        color: #fff;
        padding: 15px;
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 0.9rem;
        border-radius: 5px;
        // text-align: center;
        svg {
          width: 30px;
        }
        span {
          margin-right: 20px;
        }
        &:hover {
          background-color: #fff;
          color: $mainColor;
          span {
            &:before {
              color: $mainColor;
            }
          }
          svg {
            fill: $mainColor !important;
          }
        }
        &.active {
          background-color: #fff;
          color: $mainColor;
          span {
            &:before {
              color: $mainColor;
            }
          }
          a {
          }
        }
      }
    }
  }

  // .side-nav .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type{
  //   padding: 0 ;
  // }
  .MuiAccordion-root {
    // padding: 15px;
    background-color: transparent;
    box-shadow: none;
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
      fill: #fff;
    }
    span {
      margin-right: 20px;
      font-family: "Baloo Bhaijaan 2", cursive;
      color: #fff;
    }
  }
  .MuiTypography-root {
    font-family: "Baloo Bhaijaan 2", cursive;
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#panel1a-header svg {
  fill: #fff;
}

.form_style {
  background-color: #fff;
  padding: 65px;
  width: 80%;
  max-width: 100%;
  border-radius: 10px;
  margin-top: 150px;
  .form_header {
    margin-bottom: 35px;
  }
  h5 {
    font-size: 1.5rem;
    color: $mainColor;
  }
  p {
    font-size: 0.9rem;
    color: #000;
  }
  .form-group {
    margin-bottom: 20px;
    .form-goup-label {
      display: flex;
      align-items: center;
      label {
        width: 150px;
        font-size: 1.2rem;
        color: $mainColor;
        font-weight: 500;
        flex: none;
      }
    }
    .error {
      margin-right: 150px;
      margin-top: 5px;
      color: red;
    }

    .form-control {
      width: 60%;
      height: 50px;
      border-radius: 10px;
      background-color: #f3f3f3;
      border: 0;
      padding-right: 37px;
      &::placeholder {
        color: #9f9f9f;
        font-family: "Baloo Bhaijaan 2", cursive;
      }
    }
    textarea.form-control {
      height: 124px;
      resize: none;
    }
    select.form-control {
      width: 90%;
      color: #9f9f9f;
    }
  }
  .btn_submit {
    text-align: left;
    margin-top: 40px;
  }
  input[type="file"] {
    line-height: 50px;
  }
}

.formEditData {
  select.form-control {
    color: #000 !important;
  }
}

.Toastify__toast-body {
  font-family: "Baloo Bhaijaan 2", cursive;
  text-align: right !important;
  div {
    font-family: "Baloo Bhaijaan 2", cursive;
  }
}

a,
button {
  font-family: "Baloo Bhaijaan 2", cursive !important;
  cursor: pointer;
}

.list-unstyled {
  list-style-type: none;
  margin: 0;
}

.options_buttons {
  a,
  button {
    width: 100%;
    height: 123px;
    background-color: #fff;
    border: 1px solid #fff;
    color: $mainColor;
    border-radius: 10px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
    font-size: 1.2rem;
    &.active {
      background-color: $mainColor;
    }
    &:hover {
      background-color: $mainColor;
      color: #fff;
    }
  }
}

.table_style {
  background-color: #fff;
  padding: 37px 30px;
  margin-top: 64px;
  border-radius: 10px;
  width: 100%;
  h5 {
    color: $mainColor;
    font-size: 1.2rem;
  }
  .btn_options {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn_style {
      margin-right: 10px;
      &:first-child {
        background-color: #f3f3f3;
        border-color: #f3f3f3;
        color: $mainColor;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  .css-gl260s-MuiDataGrid-columnHeadersInner {
    // background-color: $mainColor;
    color: #000;
    width: max-content;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root[data-testid="DeleteIcon"] {
    fill: red;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root[data-testid="EditNoteOutlinedIcon"] {
    fill: $mainColor;
  }

  .form-group {
    margin-bottom: 20px;
    .form-goup-label {
      // display: flex;
      // align-items: center;
    }
    label {
      width: 150px;
      font-size: 1.2rem;
      color: $mainColor;
      font-weight: 500;
      flex: none;
      margin-bottom: 10px;
    }
    .error {
      margin-right: 150px;
      margin-top: 5px;
      color: red;
    }

    .form-control {
      width: 60%;
      height: 50px;
      border-radius: 10px;
      background-color: #f3f3f3;
      border: 0;
      padding-right: 37px;
      &::placeholder {
        color: #9f9f9f;
        font-family: "Baloo Bhaijaan 2", cursive;
      }
    }
    textarea.form-control {
      height: 124px;
      resize: none;
    }
    select.form-control {
      width: 90%;
      color: #9f9f9f;
    }
  }

  .btn_submit {
    margin-top: 15px;
    text-align: left;
  }

  .titlefor_table {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
  }
}

.map_draewr {
  margin-top: 50px;
  margin-bottom: 50px;
}

.manage_employee {
  .table_show {
    h5 {
      margin-bottom: 30px;
    }
  }
  .showall {
    position: relative;
    display: block;
    width: fit-content;
    margin-right: auto;
    text-align: left;
    margin-bottom: 40px;
    color: $mainColor;
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $mainColor;
    }
  }
}

.filter {
  form {
    display: flex;
    align-items: center;
  }
  .form-group {
    width: 25%;
    margin-bottom: 0;
  }
  .w-100 {
    width: 50%;
  }
  .btn_submit {
    width: 100px;
    margin-top: 0;
    .btn {
      background-color: $mainColor;
      border: 0;
      width: 100%;
      border: 1px solid $mainColor;
      color: #fff;
      border-radius: 5px;
      padding: 10px 20px;
    }
  }
}

input[type="date"],
input[type="number"],
input[type="email"] {
  text-align: right;
}

.inputFile {
  input {
    margin-left: 10px;
  }
  svg {
    fill: #4fa1ff;
  }
}

// .plates_list {
//   .options_buttons {
//     a {
//       background-color: $secondColor;
//       font-weight: 500;
//       &:hover {
//         background-color: #fff;
//         color: $mainColor;
//       }
//     }
//   }
// }

.MuiDataGrid-columnHeaderTitle {
  font-family: "Baloo Bhaijaan 2", cursive;
}
.css-wop1k0-MuiDataGrid-footerContainer {
  direction: ltr;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root[data-testid="LogoutOutlinedIcon"] {
  margin-right: -10px;
  width: auto !important;
}

.side-nav {
  .side_links {
    li.signout_btn {
      a {
        background-color: #ff2147;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: #ff2146c6 !important;
          color: #fff !important;
          svg {
            fill: #fff !important;
          }
        }
      }
    }
  }
}

.statics_wrapper {
  padding: 60px 0;
}
.box_style_bg {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(#000, 0.05);
  border-radius: 10px;
  padding-bottom: 25px;
  min-height: 500px;
  .box_header {
    background-color: #c4c3c1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
  }
  .day_box {
    background-color: $mainColor;
  }
}
.statics_card {
  background-color: #fff;
  border-radius: 10px;
  padding: 36px;
  box-shadow: 0 3px 6px rgba(#000, 0.05);
  text-align: center;
  margin-bottom: 20px;
  h5 {
    color: $mainColor;
    font-size: 1.5rem;
    font-weight: 300;
  }
  .number {
    font-size: 3rem;
    color: $mainColor;
    font-weight: 700;
  }
}

.login_page {
  min-height: 100vh;
  height: auto;
  background-color: $mainColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 50px 0;
  .wavelogin {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    // height: 100%;
  }
  .login_form {
    background-color: #fff;
    width: 35%;
    margin: auto;
    padding: 30px;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    box-shadow: 0 3px 6px rgba(#000, 0.05);
    .logo {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    .form-group {
      margin-bottom: 20px;
      label {
        margin-bottom: 5px;
        color: $mainColor;
        font-size: 1.2rem;
        font-weight: 500;
        display: block;
      }
      .form-control {
        height: 50px;
        border-radius: 5px;
        background-color: #f7f7f7;
        border: 0;
        width: 100%;
        padding: 15px;
      }
      .error {
        color: red;
        font-size: 0.9rem;
      }
    }
  }

  .btn_submit {
    .btn {
      background-color: $mainColor;
      color: #fff;
      border: 0;
      padding: 10px 20px;
      text-align: center;
      font-family: "Baloo Bhaijaan 2", cursive;
      border-radius: 5px;
      font-size: 1.1rem;
      border: 1px solid $mainColor;
      transition: all 0.3s ease-in-out;
      display: block;
      width: 100%;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        color: $mainColor;
      }
    }
  }
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  direction: ltr !important;
  padding: 0 10px !important;
  // display: none !important;
}

.MuiTablePagination-actions {
  direction: rtl;
}
.css-1sazv7p-MuiStack-root > :not(style):not(style) {
  direction: ltr !important;
  margin-top: 20px !important;
}

.react-confirm-alert-body {
  text-align: right !important;
  font-family: inherit !important;
}
.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  font-family: inherit !important;
  button {
    font-family: inherit !important;
    background-color: $mainColor !important;
  }
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  padding: 15px 5px !important;
  font-family: "Baloo Bhaijaan 2", cursive !important;

  // direction: ltr;
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  font-family: "Baloo Bhaijaan 2", cursive !important;
}

// .css-ptiqhd-MuiSvgIcon-root {
//   fill: #fff !important;
// }

.menuIcon {
  width: 50px;
  height: 50px;
  background-color: $mainColor;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  svg {
    fill: #fff;
    width: 40px;
  }
}

.backdropmenu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000, 0.5);
  z-index: -1;
  opacity: 0;
}

.backdropmenu_active {
  opacity: 1;
  z-index: 3;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  svg {
    margin-left: 5px;
    margin-top: -2px;
  }
}

.reports_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 36px;
  li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      border: 0;
      background-color: $mainColor;
      color: #ffff;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid $mainColor;
      padding: 8px 30px;
      font-family: "Baloo Bhaijaan 2", cursive;
      display: flex;
      align-items: center;
      min-width: 140px;
      max-width: 140px;
      justify-content: center;
      span {
        margin-left: 5px;
      }
      svg {
        width: 18px;
      }
      &:hover {
        background-color: #fff;
        color: $mainColor;
      }
    }
  }
}

.list_reports_bg {
  .box_header {
    background-color: $secondColor;
    color: $mainColor;
    margin-bottom: 0;
  }
  .reports_list {
    padding: 0;
  }
  li {
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    padding: 20px 30px;
    &:first-child {
      background-color: $mainColor;
      color: #fff;
    }
  }

  .reports_list_team {
    li {
      &:first-child {
        background-color: transparent !important;
        color: #000 !important;
      }
    }
  }
}

.tableoptions {
  .table_img {
    .card_img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      .img_parent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}

.statics_filter {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  form {
    display: flex;
    align-items: center;
  }
  h5 {
    font-size: 1.2rem;
    color: $mainColor;
    margin-bottom: 10px;
  }
  .form_group {
    width: calc(100% / 3);
    label {
      // width: 100%;
      display: block;
      color: $mainColor;
    }
    .form-control {
      width: 90%;
      height: 50px;
      border-radius: 10px;
      background-color: #f3f3f3;
      border: 0;
      padding-right: 37px;
    }
  }
  select {
    width: 50%;
    border: 0;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(#000, 0.05);
    height: 50px;
    padding: 10px;
    font-size: 1.1rem;
    font-family: segr;
  }
}

.custom_pagination_table {
  direction: rtl;
  margin-top: 20px;
}
.building_table_page {
  .MuiTablePagination-root {
    display: none !important;
  }
}

.custom_pagination_table2{
  .MuiPagination-root.MuiPagination-text.css-0{
    direction: rtl !important;
    margin-top: 20px !important;
  }
  .css-nhb8h9{
    flex-direction: row-reverse !important;
  }
  .MuiPagination-ul{
    flex-direction: row-reverse !important;
  }
}


@media print {
  body {
    font-size: 12px;
    color: black;
  }

  /* Additional print styles */
  /* For example, you might hide certain elements not relevant for printing */
  .header,
  .sidebar {
    display: none;
  }

  /* Adjust spacing and layout for printing */
  .content {
    margin: 0;
    padding: 0;
  }
  .MuiDataGrid-toolbarContainer.css-128fb87-MuiDataGrid-toolbarContainer,
  .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer {
    display: none;
  }
  .css-1llehkv-MuiGrid2-root {
    width: 100%;
  }
  // .css-5wly58-MuiDataGrid-root .MuiDataGrid-withBorderColor{
  //   width: fit-content;
  //   min-width: 100px;
  // }
}

// @media (max-width: 1535px) {
//   .order2intablet {
//     order: 2 !important;
//   }
//   .options_buttons ul {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 50px;
//     li {
//       a,
//       button {
//         // flex: none;
//         width: 200px;
//         margin: 15px;
//       }
//     }
//   }
//   .table_style {
//     margin-top: 0;
//   }
//   .nopaadingmobile {
//     // padding-bottom: 0 !important;
//   }

//   .manage_employee {
//     .css-i6wfpj-MuiGrid2-root {
//       --Grid-columnSpacing: 16px;
//       --Grid-rowSpacing: 16px;
//     }
//   }
// }

.dialog-info {
  padding-top: 20px;
}
.tableoptions {
  .change-pass {
    background-color: $secondColor !important;
    svg {
      fill: #000 !important;
    }
  }
  .number_of_items {
    background-color: transparent;
    color: #000;
    border: 1px solid #eee;
    display: flex !important;
    height: 35px;
    width: 40px;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    &:hover {
      background-color: #eee;
    }
  }
}

body {
  padding-right: 0 !important;
}

.gm-style .gm-style-iw-c {
  padding-right: 12px !important;
}

.map_dialog .MuiPaper-root {
  width: 900px;
  max-width: 100%;
}

@media (max-width: 1300px) {
  .side-nav .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type span,
  .side-nav .side_links li a span {
    margin-right: 5px;
  }
  .side-nav {
    width: 20%;
  }
  .main_content .main_content_wrapper {
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .main_content .main_content_wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .login_page .login_form {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .side-nav {
    width: max-content;
    position: fixed;
    top: 0;
    right: -100%;
    transition: all 0.3s ease-in-out;
    z-index: 7;
    overflow-y: auto;
    height: 100vh;
  }
  .add_button {
    flex-wrap: wrap;
    a {
      text-align: center;
      font-size: 1rem;
      padding: 15px;
      height: auto;
      width: 40%;
      margin-bottom: 10px;
    }
  }
  .options_buttons ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      width: 40%;
      margin-left: 20px;
    }
  }
  .options_buttons a,
  .options_buttons button {
    // width: 40%;
    height: auto;
    padding: 15px;
  }
  .menuIcon {
    display: flex;
  }
  .openmenu {
    right: 0;
  }
  .main_content .main_content_wrapper {
    width: 100%;
  }
  .login_page .login_form {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .add_button a {
    margin-left: 20px;
  }
  .filter form {
    flex-wrap: wrap;
  }
  .filter .form-group {
    width: 100%;
    margin-bottom: 10px;
  }
  .form_style {
    width: 100%;
    padding: 35px;
  }
  .form_style .form-group .form-goup-label {
    align-items: flex-start;
    flex-direction: column;
  }
  .form_style .form-group .form-control,
  .form_style .form-group select.form-control {
    width: 100%;
  }
  .login_page .login_form {
    width: 100%;
  }
  .notification_component {
    // max-width: 250px;
    margin-top: 60px !important;
  }
  .statics_filter form{
    flex-direction: column;
    .form_group{
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .list_reports_bg .reports_list_team li:first-child {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
